import Swup from "swup";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupBodyClassPlugin from "@swup/body-class-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupHeadPlugin from "@swup/head-plugin";

const linkInclude = [
  'a[href]',
];

const linkExclude = [
  '[download]',
  '[target="_blank"]',
  '[data-no-swup]',
  '[href$=".pdf"]',
  '[href$=".rss"]',
  '[href^="/podcasts"]',
  '[href^="/podcast"]',
  `[href^="${window.location.origin}/podcasts"]`,
  `[href^="${window.location.origin}/podcast"]`
];

// This list should be kept up to date with podcast redirects in `config/routes/podcasts.rb`
const excludedRailsRedirects = [
  "/national/programmes/atthemovies",
  "/international/programmes/datelinepacific",
  "/programmes/black-sheep",
  "/national/programmes/countrylife",
  "/national/programmes/focusonpolitics",
  "/national/programmes/mediawatch",
  "/national/programmes/musicalchairs",
  "/national/programmes/ourchangingworld",
  "/national/programmes/spectrum",
  "/national/programmes/thesampler",
  "/national/programmes/enzology",
  "/national/programmes/flyingnunrecords",
  "/national/programmes/blerta",
  "/national/programmes/smart_talk",
  "/national/programmes/introducing",
  "/national/programmes/secretlife",
  "/national/programmes/essentialnzalbums",
  "/international/programmes/tagata-o-te-moana",
  "/national/programmes/the-mixtape",
  "/national/programmes/voices",
  "/national/programmes/eyewitness",
  "/national/programmes/a-history-of-student-radio",
  "/programmes/a-wrinkle-in-time",
  "/programmes/my-heels-are-killing-me",
  "/programmes/monday-politics",
  "/programmes/oldest-profession",
  "/programmes/from-zero",
  "/programmes/go-ahead-caller",
  "/programmes/the-science-of",
  "/programmes/public-enemy",
  "/programmes/great-ideas",
  "/national/programmes/the-house",
  "/programmes/iceberg",
  "/programmes/the-9th-floor",
  "/programmes/healthy-or-hoax",
  "/programmes/slice-of-heaven",
  "/programmes/flying-solo",
  "/programmes/caucus",
  "/programmes/are-we-there-yet",
  "/programmes/bang",
  "/national/programmes/crowded-house",
  "/programmes/the-long-walk",
  "/programmes/the-lost",
  "/programmes/nz-wars",
  "/programmes/pants-on-fire",
  "/programmes/your-money",
  "/programmes/it-takes-a-village",
  "/programmes/gone-fishing",
  "/programmes/kiwaha",
  "/programmes/beyond-kate",
  "/programmes/eating-fried-chicken",
  "/programmes/sportsman-of-the-year",
  "/programmes/kakapo-files",
  "/programmes/elemental",
  "/programmes/the-worst-sitcom-ever-made",
  "/programmes/nanogirls-great-science-adventures",
  "/programmes/the-detail",
  "/programmes/conversations-with-my-immigrant-parents",
  "/programmes/matangireia",
  "/programmes/killjoy",
  "/programmes/nzwars-waitara",
  "/programmes/hekakanoahau",
  "/programmes/white-silence",
  "/programmes/the-aotearoa-history-show",
  "/programmes/best-of-storytime-rnz",
  "/programmes/getting-better",
  "/programmes/widows-of-shuhada",
  "/programmes/coastwatchers",
  "/programmes/the-guest-house",
  "/programmes/the-citizens-handbook",
  "/programmes/post-covid-podcast",
  "/programmes/the-service",
  "/programmes/sa-ili-malo",
  "/programmes/crimes-nz",
  "/programmes/the-unthinkable",
  "/programmes/only-human",
  "/programmes/party-people",
  "/programmes/fragments-firsthand-accounts-of-the-february-2011-earthquake",
  "/programmes/nzwars-tainui",
  "/programmes/short-story-stack",
  "/programmes/inside-the-bubble",
  "/programmes/fight-for-the-wild",
  "/programmes/generation-covid",
  "/programmes/untold-pacific-history",
  "/programmes/bob-dylan-nz",
  "/programmes/sci-fi-sci-fact",
  "/programmes/red-line",
  "/programmes/re_covering",
  "/programmes/hair-and-loathing",
  "/programmes/the-elephant-in-the-bedroom",
  "/programmes/let-s-be-transparent",
  "/programmes/news-to-me",
  "/programmes/whakamaori",
  "/programmes/mr-lyttle-meetsmr-big",
  "/programmes/deer-wars",
  "/programmes/fair-game",
  "/programmes/did-titanic-sink",
  "/programmes/the-art-of-entertaining",
  "/programmes/nau-mai-town",
  "/programmes/conviction-the-christchurch-civic-creche-case",
  "/programmes/nz-wars-stories-of-wairau",
  "/programmes/mata-with-mihingarangi-forbes",
  "/programmes/mapuna-podcast",
  "/programmes/the-tahi",
  "/programmes/undercurrent-podcast",
  "/programmes/uncle-bertie",
  "/programmes/the-river",
  "/programmes/kim-hill-collection",
  "/programmes/its-personal-with-anika-moa",
  "/programmes/voice-of-tangaroa",
  "/programmes/nellies-baby",
  "/programmes/suzy-and-friends",
  "/programmes/30-with-guyon-espiner",
  "/programmes/nz-sporting-history",
  "/programmes/music-101-interviews",
  "/programmes/nz-wars-stories-of-tauranga-moana",
  "/programmes/thrift",
];

const exclude =
  linkExclude.map((selector) => `:not(${selector})`).join("") +
  excludedRailsRedirects.map((path) => `:not([href^="${path}"])`).join("");

const linkSelector = linkInclude
  .map((include) => `${include}${exclude}`)
  .join(",");

const swup = new Swup({
  linkSelector,
  plugins: [
    new SwupProgressPlugin(),
    new SwupBodyClassPlugin(),
    new SwupHeadPlugin(),
    new SwupScriptsPlugin(),
  ],
});

const ignoredLinksInHeader = [`${window.location.origin}/podcasts`];

const interceptClickForComponent = (component) => {
  document
    .querySelector(component)
    .shadowRoot.addEventListener("click", (event) => {
      const anchor = event.target.closest("a");

      if (!anchor) {
        return;
      }
      if (ignoredLinksInHeader.includes(anchor.href)) {
        return;
      }

      event.preventDefault();
      swup.navigate(anchor.href);
    });
};

const nielsenTrackPage = () => {
  if (window.nSdkInstance) {
    const staticMetadataObject = window.staticMetadataObject;

    window.nSdkInstance.ggPM("staticend", staticMetadataObject);

    staticMetadataObject.referrerURL =
      staticMetadataObject.pageURL || window.location;
    staticMetadataObject.pageURL = "page_" + window.location;
    staticMetadataObject.assetid = `${window.location.pathname}`;

    window.nSdkInstance.ggPM("staticstart", staticMetadataObject);

    window.staticMetadataObject = staticMetadataObject;
  }
};

const configureChartbeat = () => {
  const config = window._sf_async_config || {};

  config.uid = 65651; // ACCOUNT NUMBER
  config.domain = "www.radionz.co.nz"; // DOMAIN
  config.flickerControl = false;
  config.useCanonical = true;

  window._sf_async_config = config;
  window._sf_startpt = new Date().getTime();

  loadChartbeat();

  addScript("//static.chartbeat.com/js/chartbeat_mab.js");
  addScript("//static.chartbeat.com/js/chartbeat.js");
};

const loadChartbeat = () => {
  const config = window._sf_async_config || {};

  var paths = window.location.pathname.replace(/^\//, "").split("/");

  if (["national", "international", "concert"].includes(paths[0].toLowerCase())) {
    paths = paths.slice(0, 3);
  } else {
    paths = paths.slice(0, 2);
  }

  config.sections = paths.join(",");
  config.authors = document.getElementById("page-authors")?.value || "";
  config.title = document.getElementsByTagName("title")[0]?.innerHTML || "";

  window._sf_async_config = config;
};

const loadShieldedSite = () => {
  var frameName = new ds07o6pcmkorn({
    openElementId: "#shielded-logo",
    modalID: "shielded-site-modal",
  });

  frameName.init();
};

const chartbeatTrackPage = (visit) => {
  if (window.pSUPERFLY) {
    loadChartbeat();

    const config = window._sf_async_config || {};

    window.pSUPERFLY.virtualPage({
      section: config.sections,
      authors: config.authors,
      path: visit.to.url,
      title: config.title,
      referrerURL: `${window.location.host}${visit.from.url}`,
    });
  }
};

const addScript = (src) => {
  var e = document.createElement("script");
  var n = document.getElementsByTagName("script")[0];

  e.type = "text/javascript";
  e.async = true;
  e.src = src;

  n.parentNode.insertBefore(e, n);
};

const reinitializeComponent = (component) => {
  component.disconnectedCallback();
  component.connectedCallback();
};

const updateHeaderRoute = (visit) => {
  const from = visit.from.url.split("/").filter((n) => n).at(0);
  const to = document
    .getElementById("swup")
    .getAttribute("data-route")
  document
    .getElementById("rnz-site-header")
    .attributeChangedCallback("route", from, to);
};

const waitForScript = (scriptSrc, callback) => {
  // If script already exists on the page
  const script = document.querySelector(`script[src$="${scriptSrc}"]`);
  if(script){
    callback();
    return
  }

  // If script doesn't exist on the page
  const observer = new MutationObserver((mutations, obs) => {
    const script = document.querySelector(`script[src$="${scriptSrc}"]`);
    if (script) {
      obs.disconnect();
      script.addEventListener("load", () => {
        callback();
      });
    }
  });

  observer.observe(document, {
    childList: true,
    subtree: true,
  });
};

const reinitializeComponents = () => {
  reinitializeComponent(document.getElementById("rnz-site-header"));
  reinitializeComponent(document.getElementById("rnz-site-footer"));
  reinitializeComponent(document.getElementById("rnz-queue-player"));

  const queueMediaComponents = document.getElementsByTagName("rnz-queue-media");
  for(let i = 0; i < queueMediaComponents.length; i++) {
    reinitializeComponent(queueMediaComponents[i]);
  }
}

const init = () => {
  configureChartbeat();
  loadShieldedSite();

  interceptClickForComponent("rnz-site-header");
  interceptClickForComponent("rnz-site-footer");

  // Reload web components after the Nielsen script has loaded
  waitForScript("nlsSDK600.bundle.min.js", () => reinitializeComponents());
};

document.addEventListener("DOMContentLoaded", () => {
  init();
  nielsenTrackPage();
});

swup.hooks.on("page:view", (visit) => {
  nielsenTrackPage();
  chartbeatTrackPage(visit);

  // Run the US Election 2024 script
  US24CreatePollingChart();

  // Set the global variable to false to indicate that the Flourish script has not been loaded on swup navigation
  window.FlourishLoaded = false
});

swup.hooks.on("content:replace", (visit) => {
  updateHeaderRoute(visit);
});
